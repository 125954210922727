import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {IssGetDataById} from "../Resourses/IssAPI/IssGetDataByIdClass";
import {IssGetGearById} from "../Resourses/IssAPI/IssGetGearByIdClass";
import PrintPageComponent from "./PrintPageComponent";
import styled from "styled-components";
import {aaa, bbb} from "./StyledComponents"
import PrintCertComponent from "./PrintCertComponent";
import PrintUnloadComponent from "./PrintUnloadComponent";

function RenderSinglePage(elements) {

    const [ports, setPorts] = useState([])
    const [endPort, setEndPort] = useState([]);
    const [unloadPort, setUnloadPort] = useState([]);
    const [gearName, setGearName] = useState(false);
    let ulov = false

    function handleEndPort(hp) {
        let p = {"id": hp[0], "n": hp[1]}
        setEndPort((endPort) => [...endPort, p])
    }

    function handleUnloadPort(hp) {
        let p = {"id": hp[0], "n": hp[1]}
        setUnloadPort((unloadPort) => [...unloadPort, p])
    }

    function handlePorts(hp) {
        let p = {"id": hp[0], "n": hp[1]}
        setPorts((ports) => [...ports,  p])

    }

    let page = elements[0]
    if (typeof page !== "undefined") {
        if (typeof page.page_ulov !== "undefined") {
            if (page.page_ulov.length !== 0) {
                // if (typeof page.page_ulov !== "undefined") {
                ulov = page.page_ulov[0]
            }
        }
    }

    let sid = elements.sid
    let cfr = elements.cfr


    function handleGearName(hp) {
        setGearName(hp)
    }


    useEffect(() => {
        if (ports.length > 0) {
            let result;
            ports.filter(item => item.id === page["start_port"]).map(filteredItem => (
                result = filteredItem
            ))
            if (typeof result === "undefined") {
                IssGetDataById(sid, cfr, page["start_port"], "ports/", handlePorts)
            } else {
                setPorts((ports) => [...ports, result])
            }
        } else {
            IssGetDataById(sid, cfr, page["start_port"], "ports/", handlePorts)
        }

        IssGetGearById(sid, cfr, page["ured_id"], "gears/id/", handleGearName)

    }, [])

    useEffect(() => {

        if (ports.length > 0) {
            let result;
            ports.filter(item => item.id === page["end_port"]).map(filteredItem => (
                result = filteredItem
            ))
            let enport;
            endPort.filter(item => item.id === page["end_port"]).map(filteredItem => (
                enport = filteredItem
            ))
            if (typeof result === "undefined") {
                IssGetDataById(sid, cfr, page["end_port"], "ports/", handleEndPort)
            } else {
                setEndPort((endPort) => [...endPort, result])
            }
        }
    }, [ports])

    useEffect(() => {

        if (ports.length > 0) {
            let enport;
            endPort.filter(item => item.id === page["unload_port"]).map(filteredItem => (
                enport = filteredItem
            ))
            let stport;
            ports.filter(item => item.id === page["unload_port"]).map(filteredItem => (
                stport = filteredItem
            ))
            let unport;
            unloadPort.filter(item => item.id === page["unload_port"]).map(filteredItem => (
                unport = filteredItem
            ))
            if (typeof enport === "undefined" && typeof unport === "undefined" && typeof stport === "undefined") {
                IssGetDataById(sid, cfr, page["end_port"], "ports/", handleUnloadPort)
                console.log("MADE REQ")
            } else if (typeof enport === "undefined") {
                if (typeof unport === "undefined") {
                    setUnloadPort((unloadPort) => [...unloadPort, stport])
                }
            } else {
                setUnloadPort((unloadPort) => [...unloadPort, enport])
            }
        }
    }, [endPort])


    return (
        <BaseSection>
            {typeof page.page_num === "undefined" || typeof page === "undefined" || page.del_flag === 1 ? "Страницата не е открита." :
                <>
                    <OriginSection>
                        <Row>
                            <Col aria-colspan={1}> </Col>
                            <Col aria-colspan={5}>
                                <div><b>ДЕКЛАРАЦИЯ ЗА ПРОИЗХОД | СТРАНИЦА ОТ КОМБИНИРАН РИБОЛОВЕН ДНЕВНИК НА ЕС</b>
                                </div>
                            </Col>
                            <Col aria-colspan={1}>
                                <div><b>№: {page["page_num"]}</b></div>
                            </Col>
                        </Row>
                        <div className="shipsdata">
                            <Row>
                                <Col>
                                    <div><b>Плавателен съд:</b></div>
                                </Col>
                                <Col>
                                    <div><b>CFR:</b></div>
                                </Col>
                                <Col>
                                    <div><b>Външна маркировка:</b></div>
                                </Col>
                                <Col>
                                    <div><b>Капитан/титуляр:</b></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>{page["ship_name"]}</Col>
                                <Col>{cfr}</Col>
                                <Col>{page["extmark"]}</Col>
                                <Col>{page["capitan"]}</Col>
                            </Row>
                        </div>
                        <div className="basedata">
                            <div className="partgear">
                                {page["has_partship"] ?
                                    <div className="partner">
                                        {/*common case: missing partner vessel; additional request needed for the partner vessel`s master*/}
                                        <Row>
                                            <Col>
                                                <div><b>Партниращ плавателен съд:</b></div>
                                            </Col>
                                            <Col>
                                                <div><b>Външна маркировка:</b></div>
                                            </Col>
                                            <Col>
                                                <div><b>Капитан/титуляр:</b></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>{page["partship_name"]}</Col>
                                            <Col>{page["partship_extmark"]}</Col>
                                            <Col>XXX</Col>
                                        </Row>
                                    </div> : null}
                                <div className="gear">
                                    <Row>
                                        <Col>
                                            <div><b>Риболовен уред - код:</b></div>
                                        </Col>
                                        <Col>
                                            <div><b>Риболовен уред - наименование:</b></div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>{gearName.code}</Col>
                                        <Col>{gearName.name}</Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="dates">
                                <Row>
                                    <Col> </Col>
                                    <Col>
                                        <div><b>Дата:</b></div>
                                    </Col>
                                    <Col> </Col>
                                    <Col>
                                        <div><b>Пристанище:</b></div>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col>
                                        <div><b>Тръгване: </b></div>
                                    </Col>
                                    <Col>{page["start_date"]}</Col>
                                    <Col>
                                        <div><b>от: </b></div>
                                    </Col>
                                    <Col>{ports.length > 0 ? ports[0]['n'] : 'NA'}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div><b>Връщане: </b></div>
                                    </Col>
                                    <Col>{page["end_date"]}</Col>
                                    <Col>
                                        <div><b>в: </b></div>
                                    </Col>
                                    <Col>{endPort.length > 0 ? endPort[0]['n'] : 'NA'}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div><b>Разтоварване: </b></div>
                                    </Col>
                                    <Col>{page["unload_date"]}</Col>
                                    <Col>
                                        <div><b>в: </b></div>
                                    </Col>
                                    <Col>{unloadPort.length > 0 ? unloadPort[0]['n'] : 'NA'}</Col>
                                </Row>
                            </div>
                        </div>
                        <div className="ulovdata">
                            <Row>
                                <Col>
                                    <div><b>Улов по видове риба и други морски организми в килограми живо тегло или брой
                                        използвани
                                        единици за съхранение:</b></div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row style={{backgroundColor: "#e1e1e1", margin: "0.5rem"}}>
                                <Col><p><b>Дата: </b>{ulov["data"]}</p></Col>
                                <Col><p><b>Брой риболовни операции: </b>{ulov["operacii"]}</p></Col>
                                <Col><p><b>Зона на улова (FAO): </b>{ulov["zona"]}</p></Col>
                                <Col><p><b>Квадрант: </b>{ulov["pravoagalnik"] ? ulov["pravoagalnik"] : "NA"}</p></Col>
                            </Row>
                            <Row>
                                <Col><p><b>Вид:</b></p></Col>
                                <Col><p><b>Количество:</b></p></Col>
                                <Col><b>Мерна единица:</b></Col>
                                <Col><p><b>Сертификат за произход:</b></p></Col>
                            </Row>
                            {ulov ? Object.entries(ulov["fishs"]).map((fish, k) => {
                                // console.log("FISH: " + JSON.stringify(fish[1]["qty"]));

                                return (
                                    <div key={k}>
                                        {/*{fish[1]["qty"] !== 0 ?*/}
                                        <Row>
                                            {/*{console.log(JSON.stringify(fish[1]))}*/}
                                            <Col><p>{fish[1]["code"]} - {fish[1]["name"]}</p></Col>
                                            <Col>{fish[1]["qty"]}</Col>
                                            <Col>{fish[1]["pres_measure"]}</Col>
                                            <Col>
                                                {page && gearName && ulov && fish[1]["qty"] !== 0 ?
                                                    <PrintCertComponent
                                                        default_page={page}
                                                        gear_name={gearName}
                                                        fish_name={fish[1]["name"]}
                                                        fish_code={fish[1]["code"]}
                                                        ulov={ulov}
                                                        cfr={cfr}/>
                                                    : null}
                                            </Col>
                                        </Row>
                                        {/*: null}*/}
                                        {/*<Row>*/}
                                        {/*    <Col>{fish[0]["qty"]}</Col>*/}
                                        {/*</Row>*/}
                                    </div>
                                )
                            }) : "NA"}
                        </div>
                        <div className="unload">
                            <Row>
                                <Col>
                                    <div><b>Разтоварване по видове, в килограми живо тегло или брой опаковки:</b></div>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col><b>Вид:</b></Col>
                                <Col><b>Kоличество:</b></Col>
                                <Col><b>Мерна единица:</b></Col>
                                <Col><b>Представяне:</b></Col>
                            </Row>
                            {ulov ? Object.entries(ulov["fishs"]).map((fish, k) => {
                                // console.log("FISH: " + JSON.stringify(fish[1]["qty"]));
                                return (
                                    <div key={k}>
                                        {/*{fish[1]["qty"] !== 0 ?*/}
                                        <Row>
                                            <Col>{fish[1]["code"]} - {fish[1]["name"]}</Col>
                                            <Col>{fish[1]["qty"]}</Col>
                                            <Col>{fish[1]["pres_measure"]}</Col>
                                            <Col>{fish[1]["pres_name"]}</Col>
                                        </Row>
                                        {/*: null}*/}
                                    </div>
                                )
                            }) : "NA"}
                            <br/>
                            {page && ports && endPort && unloadPort && gearName && ulov ?
                                unloadPort !== false ?
                                    <div>
                                        <PrintUnloadComponent
                                            default_page={page}
                                            start_port={ports}
                                            end_port={endPort}
                                            unload_port={unloadPort}
                                            gear_name={gearName}
                                            ulov={ulov}
                                            cfr={cfr}/>
                                    </div>
                                    : null
                                : null}
                        </div>


                        {page["has_trans"] === 1 ?
                            <div className="trans">
                                <Row>
                                    <div><b>Трансбордиране по видове, в килограми живо тегло или брой опаковки:</b>
                                    </div>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col>
                                        <div><b>Дата на трансбордиране:</b></div>
                                    </Col>
                                    <Col>
                                        <div><b>Риболовен кораб:</b></div>
                                    </Col>
                                    <Col>
                                        <div><b>Радиопозивна:</b></div>
                                    </Col>
                                    <Col>
                                        <div><b>Националност:</b></div>
                                    </Col>
                                    <Col>
                                        <div><b>Предназначено за пристанище:</b></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>{page["trans_date"]}</Col>
                                    <Col>{page["trans_ship"]}</Col>
                                    <Col>{page["trans_pozivna"]}</Col>
                                    <Col>{page["trans_nacionalnost"]}</Col>
                                    <Col>{page["transp_port_target"]}</Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col><b>Вид:</b></Col>
                                    <Col><b>Kоличество:</b></Col>
                                    <Col><b>Мерна единица:</b></Col>
                                    <Col><b>Представяне:</b></Col>
                                    <Col><b>Състояние:</b></Col>
                                    <Col><b>Зона на ICES/NAFO/CECAF/CFGM:</b></Col>
                                    <Col><b>Зона за риболов на трети държави:</b></Col>
                                </Row>
                                <Row>
                                    <Col>XXX</Col>
                                    <Col>XXX</Col>
                                    <Col>XXX</Col>
                                    <Col>XXX</Col>
                                    <Col>XXX</Col>
                                    <Col>XXX</Col>
                                    <Col>XXX</Col>
                                </Row>
                                <br/>
                                <Button variant="outline-primary">Принтиране деларация за трансбордиране</Button>
                            </div> : null}
                        {page && ports && endPort && unloadPort && gearName && ulov ?

                            <PrintPageComponent
                                //props:
                                default_page={page}
                                start_port={ports}
                                end_port={endPort}
                                unload_port={unloadPort}
                                gear_name={gearName}
                                ulov={ulov}
                                cfr={cfr}/>
                            :
                            null
                        }
                    </OriginSection>
                </>
            }
        </BaseSection>
    )
}

export default RenderSinglePage;

const BaseSection = styled.section`

`;


const OriginSection = styled.section`
  display: grid;
  gap: 1rem;
  ${aaa};

  .shipsdata {
    ${bbb}
  }

  .basedata {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .partgear {
      display: grid;
      gap: 1rem;

      .partner {
        ${bbb}
      }

      .gear {
        ${bbb}
      }
    }

    .dates {
      ${bbb}
    }
  }

  .ulovdata {
    ${bbb}
  }

  .unload {
    ${bbb}
  }

  .trans {
    ${bbb}
  }`;
