import {Accordion, Button, Card, Col, Dropdown, Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {IssVesselJournalsYearArchive} from "../Resourses/IssAPI/IssGetJournalsYearArchiveClass";
import RenderPages from "./RenderPagesComponent";

function Archive(props) {

    const [yearValue, setYearValue] = useState(new Date().getFullYear() -1);
    const [journalsArchive, setJournalsArchive] = useState(false);
    const [btnClicked, setBtnClicked] = useState(false);

    function changeValue(y) {
        setYearValue(y.currentTarget.textContent)
    }

    function handleJournalsYearArchive(data) {
        setJournalsArchive(data)
    }
    const getArchiveJournalsYear = () => {
        setJournalsArchive(false)
        IssVesselJournalsYearArchive(props, yearValue, handleJournalsYearArchive);
        setBtnClicked(true)
    }

    function range(start, end) {
        var ans = [];
        let s = [];
        for (let i = start; i <= end; i++) {
            ans.push({i,"page":{}});
        }
        let p={
            "sid" : props.sid,
            "cfr": props.cfr,
            "uid": props.uid,
            "nm": props.nm};
        s.push(ans)
        s.push(p)

        return s;
    }

    function RenderFunction() {

        return(
            Object.entries(journalsArchive).map((subItem, n) => (
                // subItem[1].udo_nomer === props.item ?
                    <Card key={n}>
                        <Card.Body>
                            <Card.Title>{subItem[1].dnevnik_nomer}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                {subItem[1].dnevnik_nomer
                                    .split('K').length > 1 ? "Дневник калкан"
                                    : "Общо"}
                            </Card.Subtitle>
                            <Row>
                                <Col><b>Кораб</b></Col>
                                <Col><b>CFR</b></Col>
                                <Col><b>Дневник №</b></Col>
                                <Col><b>Заверен дата</b></Col>
                                <Col><b>Валиден до</b></Col>
                                <Col><b>Начална страница</b></Col>
                                <Col><b>Крайна страница</b></Col>
                                <Col><b>Последна страница</b></Col>
                            </Row>
                            <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                            <Row>
                                <Col>{subItem[1].name}</Col>
                                <Col>{subItem[1].cfr}</Col>
                                <Col>{subItem[1].dnevnik_nomer}</Col>
                                <Col>{subItem[1].dnevnik_start_date}</Col>
                                <Col>{subItem[1].udo_valid_to}</Col>
                                <Col>{subItem[1].dnevnik_start_page}</Col>
                                <Col>{subItem[1].dnevnik_end_page}</Col>
                                <Col>{subItem[1].current_page}</Col>
                            </Row>
                            {subItem[1].current_page !== -1 ?
                                <div><RenderPages {...range(subItem[1].dnevnik_start_page, subItem[1].current_page)}/></div>
                                : null}
                        </Card.Body>
                    </Card>
            ))
        )
    }

    return(
        <>
            <Accordion>
                <Accordion.Item eventKey={0}>
                    <Accordion.Header>
                        Архив
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col>
                                <div>Търсене на дневници за
                                <Dropdown className="d-inline mx-2">
                                    <Dropdown.Toggle id="dropdown-autoclose-true" variant={"outline-secondary"}>
                                        {yearValue}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={changeValue}>2021</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={changeValue}>2020</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={changeValue}>2019</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={changeValue}>2018</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={changeValue}>2017</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                година
                            <Button style={{marginLeft: "10px"}} onClick={getArchiveJournalsYear}>Търсене</Button></div>
                            </Col>
                        </Row>
                        <br/>
                        {btnClicked ?
                            journalsArchive ?
                                <RenderFunction />
                                :
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            :
                            null}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default Archive
