import {Accordion, Button} from "react-bootstrap";
import RenderSinglePage from "./RenderSinglePageComponent";
import {IssVesselSinglePage} from "../Resourses/IssAPI/IssVesselSinglePageClass";
import {useState} from "react";

function RenderPages(props) {

    const style = {
        "accordion-body": {
            "padding": "0"
        }
    }
    const [st, setSt] = useState(false);
    const [pages, setPages] = useState({props})

    function ab(pr) {
        setSt(JSON.stringify(pr).toString)
    }

    const  HandlePage = async (it) => {
        let isspage = await IssVesselSinglePage(it, pages.props[1], ab);
        // console.log("typeof isspage" + typeof isspage[0]["error"] === "undefined")
        if(typeof isspage !== "undefined") {
            // console.log("ISSPAGES: "+JSON.stringify(isspage[0]["error"]));
            const newPages = Object.entries(pages.props[0]).map((p, i) => {
                const NewwPages = Object.assign({}, pages);
                if(p[1].i === it){
                    NewwPages.props[0][p[0]].page = {...isspage}
                    setPages(NewwPages)
                }
            });
            // console.log("HandleData data -> "+ JSON.stringify(props))

            return  (
                <>WITH FOCUS</>
            )
        }
        else {
            console.log("TTTFGF")
        }
       return (
           st ? <>ST</> : <>ELSE</>
       )
    }

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey={0}>
                    <Accordion.Header>
                        Страници
                    </Accordion.Header>
                    {Object.entries(pages.props[0]).map((item, i)=>
                        <Accordion.Body key={i} style={style["accordion-body"]}>
                            <Accordion>
                                <Accordion.Item eventKey={0}>
                                    <Accordion.Header>
                                        Страница №: {item[1].i}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {Object.keys(item[1].page).length !==0  ? <RenderSinglePage {...item[1].page} {...pages.props[1]}/> :
                                            <Button onClick={() => HandlePage(item[1].i)}>Покажи</Button>}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    )}
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default RenderPages
