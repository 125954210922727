import {Button, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {IssVesselGears} from "../Resourses/IssAPI/IssVesselGearsClass";
import GearComponent from "./GearComponent";

function CertsCheck(propData) {

    const [gears, setGears] = useState(false);
    const [certificates, setCetrificates] = useState(false);
    const [formated, SetFormated] = useState(false);
    const [btnClicked, setBtnClicked] = useState(false);

    function handleData(props) {
        let d = []
        Object.entries(props).map((kk) => {
            d.push(kk[1])
        })
        SetFormated(d)
        setGears(props)
    }

    function isEmptyObject(item) {
        if(Object.keys(item).length === 0 && item.constructor === Object) {
            return true
        }
        return false
    }

    const getGears = () => {
        IssVesselGears(propData["sid"], propData["cfr"], propData["uid"], handleData);
        setBtnClicked(true);
    }

    function handleCerts(arr) {
        let cer = [];
        Object.entries(arr).map(([k, idx]) => {
            console.log(idx.rsr);
            if (!cer.includes(idx.rsr)) {
                cer.push(idx.rsr);
            }
        })
        setCetrificates(cer);
        console.log("certificates: " + JSON.stringify(certificates));

        return (
            Object.entries(certificates).map(([c, i]) => {
                console.log("handleCerts: certificates[c]: " + JSON.stringify(certificates[c]));
                <div>handleUDO</div>
            })
        )
    }

    if (gears === false) {
        if (btnClicked === true) {
            return (
                <Spinner
                    as="span"
                    animation="grow"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                />
            )
        } else {
            return (
                <Button onClick={() => getGears()}>Проверка за разрешителни</Button>
            )
        }
    }
    if(isEmptyObject(gears)){
        return (
            <div>Не са открити активни удостоверения към разрешителни</div>
        )
    }
    return (
        ({gears} ?
            {certificates} ?
                <div><GearComponent {...gears} {...certificates} vessel={propData}/></div>
                :
                <div>{() => handleCerts(gears)}</div>
            :
            <Button onClick={() => getGears()}>Проверка за актуални разрешителни</Button>)
    )
}

export default CertsCheck;
