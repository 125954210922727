const __WIALON_BASE_URI = "/wialon/ajax.html?";
const __WIALON_AUTH_URI = "svc=core/use_auth_hash";
const __WIALON_API_URL = __WIALON_BASE_URI;
const __WIALON_ITEM_URI = "svc=core/search_items"
const user = "";

export const WialonAuth = async (authHash, callback) => {
    let searchParams = new URLSearchParams();
    let params = {
        "authHash": authHash,
        "operateAs": user
    }
    searchParams.append("params", JSON.stringify(params).toString())
    const sessOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*'},
        body: searchParams.toString()
    };
    const response = await fetch(__WIALON_API_URL+__WIALON_AUTH_URI, sessOptions);
    const data = await response.json();
    let eid = data["eid"];
    if(data.error){
        callback(data, eid)
        return
    }

    searchParams = new URLSearchParams();
    params = {
        "spec": {
            "itemsType": "avl_unit",
            "propName": "rel_hw_type_id",
            "propValueMask": "17",
            "sortType": "",
            "propType": "property",
            "or_logic": false
        },
        "force": 1,
        "flags": 256 + 1 + 8,
        "from": 0,
        "to": 0
    }
    searchParams.append("params", JSON.stringify(params).toString())
    searchParams.append("sid", eid)
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*'},
        body: searchParams.toString()
    };
    // console.log(__WIALON_API_URL+__WIALON_ITEM_URI, requestOptions)
    // console.log("req opt: "+JSON.stringify(searchParams.toString()))
    let filteredData = [];
    const res = await fetch(__WIALON_API_URL+__WIALON_ITEM_URI, requestOptions);
    const resData = await res.json();
    // console.log(resData)
    if(resData.error){
        callback(resData, eid)
    }
    if(typeof resData["items"] !== "undefined"){

        const data = resData["items"];
        Object.entries(data).map(([k, idx]) => {
                if (idx["flds"].hasOwnProperty(3)) {

                    if (idx["flds"][3].hasOwnProperty("n")) {
                        if (idx["flds"][3]["v"].length === 12) {
                            // console.log("IDX FIELDS: "+JSON.stringify(idx));
                            let v = {};
                            v["cfr"] = idx["flds"][3]["v"];
                            v["nm"] = idx["nm"];
                            // v["lnm"] = idx["flds"][13]["v"];
                            v["uid"] = idx["uid"];
                            v["sid"] = eid
                            filteredData.push(v);
                        }
                    }
                }
                return
            })
        callback(filteredData, eid)
    }
    return eid;
}
