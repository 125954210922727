import React, {useRef} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import ReactToPrint from "react-to-print";

function PrintPageComponent(props) {

    let page = props.default_page
    let ports = props.start_port
    let endPort = props.end_port
    let unloadPort = props.unload_port
    let gear = props.gear_name
    let ulov = props.ulov
    let cfr = props.cfr


    let componentRef = useRef();

    return (
        <>
            <ReactToPrint
                trigger={() => <Button>Принтиране на страница</Button>}
                content={() => componentRef}
            />
            <div style={{display: "none"}}>
                <Container style={{
                    maxWidth: "2480px",
                    width: "100%",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "15px",
                    paddingBottom: "15px",
                    margin: "0 auto"
                }}
                           ref={(el) => (componentRef = el)}>
                    <Row>
                        <Col style={{textAlign: "center"}}><h4><b>ДЕКЛАРАЦИЯ ЗА ПРОИЗХОД</b></h4></Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col style={{textAlign: "left"}}><b>№: {page.page_num}</b></Col>
                        <Col style={{textAlign: "right"}}><b>Дата: {page.get_date}</b></Col>
                    </Row>
                    <div style={{border: "2px solid #8a8a8a"}}>
                        <Row style={{textAlign: "center"}}><Col><b>РИБОЛОВЕН КОРАБ</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Външна маркировка:</b></Col>
                            <Col><b>CFR номер:</b></Col>
                            <Col><b>Капитан:</b></Col>
                        </Row>
                        <Row>
                            <Col>{page.ship_name}</Col>
                            <Col>{page.extmark}</Col>
                            <Col>{cfr}</Col>
                            <Col>{page.capitan}</Col>
                        </Row>

                        {page["has_partship"] ?
                            <div>
                                <br/>
                                <Row>
                                    <Col><b>Партниращ риболовен кораб:</b></Col>
                                    <Col><b>Външна маркировка:</b></Col>
                                </Row>
                                <Row>
                                    <Col>{page.partship_name}</Col>
                                    <Col>{page.partship_extmark}</Col>
                                </Row>
                            </div> : null}
                        <br/>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <div style={{width: "100%", margin: "0 auto", display: "flex"}}>
                            <div style={{width: "50%", margin: "0 auto"}}>
                                <Row>
                                    <Col style={{textAlign: "center"}}><b>РИБОЛОВЕН УРЕД</b></Col>
                                </Row>
                                <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                                <Row><Col><b>Код:</b></Col></Row>
                                <Row><Col>{gear["code"]}</Col></Row>
                                <Row><Col><b>Наименование:</b></Col></Row>
                                <Row><Col>{gear["name"]}</Col></Row>
                                <br/>
                            </div>
                            <div className="rounded" style={{borderLeft: "2px solid #8a8a8a"}}/>
                            <div style={{width: "50%", margin: "0 auto"}}>
                                <Row>
                                    <Col style={{textAlign: "center"}}><b>СЪБИТИЯ</b></Col>
                                </Row>
                                <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                                <Row>
                                    <Col> </Col>
                                    <Col><b>Дата и час:</b></Col>
                                    <Col><b>Пристанище:</b></Col>
                                </Row>
                                <Row>
                                    <Col style={{textAlign: "right"}}>Заминаване:</Col>
                                    <Col>{page.start_date}</Col>
                                    <Col>{ports.length > 0 ? ports[0]['n'] : 'NA'}</Col>
                                </Row>
                                <Row>
                                    <Col style={{textAlign: "right"}}>Връщане:</Col>
                                    <Col>{page.end_date}</Col>
                                    <Col>{endPort.length > 0 ? endPort[0]['n'] : 'NA'}</Col>
                                </Row>
                                <Row>
                                    <Col style={{textAlign: "right"}}>Разтоварване:</Col>
                                    <Col>{page.unload_date}</Col>
                                    <Col>{unloadPort.length > 0 ? unloadPort[0]['n'] : 'NA'}</Col>
                                </Row>
                                <br/>
                            </div>
                        </div>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row style={{textAlign: "center"}}>
                            <Col><b>Улов по видове риба и други морски организми в килограми живо тегло или брой
                                използвани
                                единици за съхранение:</b></Col>
                        </Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Дата:</b></Col>
                            <Col><b>Брой операции:</b></Col>
                            <Col><b>Зона (FAO):</b></Col>
                            <Col><b>Квадрант:</b></Col>
                        </Row>
                        <Row>
                            <Col>{ulov["data"]}</Col>
                            <Col>{ulov["operacii"]}</Col>
                            <Col>{ulov["zona"]}</Col>
                            <Col>{ulov["pravoagalnik"] ? ulov["pravoagalnik"] : "NA"}</Col>
                        </Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Код:</b></Col>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Количество:</b></Col>
                            <Col><b>Мярка:</b></Col>
                        </Row>
                        {Object.entries(ulov["fishs"]).map((fish, k) => {
                            return (
                                <div key={k}>
                                    {/*{fish[1]["qty"] !== 0 ?*/}
                                    <Row>
                                        <Col>{fish[1]["code"]}</Col>
                                        <Col>{fish[1]["name"]}</Col>
                                        <Col>{fish[1]["qty"]}</Col>
                                        <Col>{fish[1]["pres_measure"]}</Col>
                                    </Row>
                                    {/*: null}*/}
                                </div>
                            )
                        })}
                        <br/>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row style={{textAlign: "center"}}>
                            <Col><b>Разтоварване по видове, в килограми живо тегло или брой опаковки:</b></Col>
                        </Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Код:</b></Col>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Количество:</b></Col>
                            <Col><b>Мярка:</b></Col>
                        </Row>
                        {Object.entries(ulov["fishs"]).map((fish, k) => {
                            return (
                                <div key={k}>
                                    {/*{fish[1]["qty"] !== 0 ?*/}
                                    <Row>
                                        <Col>{fish[1]["code"]}</Col>
                                        <Col>{fish[1]["name"]}</Col>
                                        <Col>{fish[1]["qty"]}</Col>
                                        <Col>{fish[1]["pres_measure"]}</Col>
                                    </Row>
                                    {/*: null}*/}
                                </div>
                            )
                        })}
                        <br/>
                        {page["has_trans"] ?
                            <div>
                                <Row>
                                    <Col><p>Трансбордиране по видове, в килограми живо тегло или брой опаковки:</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><b>Дата на трансбордиране:</b></Col>
                                    <Col><b>Риболовен кораб:</b></Col>
                                    <Col><b>Радиопозивна:</b></Col>
                                    <Col><b>Националност:</b></Col>
                                    <Col><b>За пристанище:</b></Col>
                                </Row>
                                <Row>
                                    <Col>{page["trans_date"]}</Col>
                                    <Col>{page["trans_ship"]}</Col>
                                    <Col>{page["trans_pozivna"]}</Col>
                                    <Col>{page["trans_nacionalnost"]}</Col>
                                    <Col>{page["transp_port_target"]}</Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col><b>Вид:</b></Col>
                                    <Col><b>Kоличество:</b></Col>
                                    <Col><b>Представяне:</b></Col>
                                    <Col><b>Място/зона на улов:</b></Col>
                                    <Col><b>Място/зона на трансбордиране:</b></Col>
                                </Row>
                                <Row>
                                    <Col>XX</Col>
                                    <Col>XX</Col>
                                    <Col>XX</Col>
                                    <Col>XX</Col>
                                    <Col>XX</Col>
                                </Row>
                            </div> : null}
                    </div>
                </Container>
            </div>
        </>
    );
}

export default PrintPageComponent;
