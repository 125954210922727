import {Spinner} from "react-bootstrap";

function Preloader () {

    return (
        <div className="border d-flex align-items-center justify-content-center" style={{height: "100vh"}}>
            <Spinner animation="grow" variant="primary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default Preloader;
