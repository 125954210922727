import React, {useEffect, useState} from "react";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import {IssVesselJournals} from "../Resourses/IssAPI/IssVesselJournalsClass";
import RenderPages from "./RenderPagesComponent";

function Journals(props) {
    const [journals, setJournals] = useState(false);

    function handleJournals(data) {
        setJournals(data)
    }
    const getJournals = () => {
        IssVesselJournals(props, handleJournals)
    }
    useEffect(() => {
        getJournals()
    }, [])

    function range(start, end) {
        var ans = [];
        let s = [];
            for (let i = start; i <= end; i++) {
                ans.push({i,"page":{}});
            }
            let p={
                "sid" : props.fun.vessel.sid,
                "cfr": props.fun.vessel.cfr,
                "uid": props.fun.vessel.uid,
                "nm": props.fun.vessel.nm};
            s.push(ans)
            s.push(p)

        return s;
    }

    const renderFunction = (data) =>{

        return(
            Object.entries(data).map((subItem, n) => (
                subItem[1].udo_nomer === props.item ?
                    <Card key={n}>
                        <Card.Body>
                            <Card.Title>{subItem[1].dnevnik_nomer}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                {subItem[1].dnevnik_nomer
                                    .split('K').length > 1 ? "Дневник калкан"
                                    : "Общо"}
                            </Card.Subtitle>
                            <Row>
                                <Col><b>Кораб</b></Col>
                                <Col><b>CFR</b></Col>
                                <Col><b>Дневник №</b></Col>
                                <Col><b>Заверен дата</b></Col>
                                <Col><b>Валиден до</b></Col>
                                <Col><b>Начална страница</b></Col>
                                <Col><b>Крайна страница</b></Col>
                                <Col><b>Последна страница</b></Col>
                            </Row>
                            <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                            <Row>
                                <Col>{subItem[1].name}</Col>
                                <Col>{subItem[1].cfr}</Col>
                                <Col>{subItem[1].dnevnik_nomer}</Col>
                                <Col>{subItem[1].dnevnik_start_date}</Col>
                                <Col>{subItem[1].udo_valid_to}</Col>
                                <Col>{subItem[1].dnevnik_start_page}</Col>
                                <Col>{subItem[1].dnevnik_end_page}</Col>
                                <Col>{subItem[1].current_page}</Col>
                            </Row>
                            {subItem[1].current_page !== -1 ?
                                <div><RenderPages {...range(subItem[1].dnevnik_start_page, subItem[1].current_page)}/></div>
                                : null}
                        </Card.Body>
                    </Card> : null
            ))
        )
    }

    return (
        <>{journals ? renderFunction(journals) : <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
        />}</>
    )
}

export default Journals;
