import WialonError1 from "./Errors/1_WialonError";
import WialonError4 from "./Errors/4_WialonError";
import WialonError8 from "./Errors/8_WialonError";

function Errors(error) {
    console.log(error.error);
    let e = error.error;
    if(e === 1){
        return (<WialonError1 />);
    }
    else if(e === 4){
        return (<WialonError4 />);
    }
    else if(e === 8){
        return (<WialonError8 />);
    }
    return(<>Undefined Error</>);
}

export default Errors;
