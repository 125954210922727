const __ISS_API_BASE_URI = "/api/";

export const IssGetDataById = (esid, cfr, id, uri, callback) => {

    let URL = __ISS_API_BASE_URI + uri + id;

    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'sid': esid,
            'cfr': cfr
        }
    };

    fetch(URL,requestOptions)
        .then(function (response) {
            // console.log(response)
            return response.json();
        })
        .then(function (Journal) {
            let d = Object.keys(Journal).map((key) => Journal[key]);
            // console.log("DATA: "+JSON.stringify(d));
            // [124,"Каварна Буна 1",null,0]
            callback(d);
            return(d);
        });
}

