const __ISS_API_BASE_URI = "/api/";
const __ISS_API_GEARS_ALL_VALID_URI = "gearsall/cfr/";

export const IssVesselGears = async (esid, CFR, NAME, callback) => {
    const URL = __ISS_API_BASE_URI+__ISS_API_GEARS_ALL_VALID_URI+CFR;

    function ascii_to_hex(str)
    {
        var arr1 = [];
        for (var n = 0, l = str.length; n < l; n ++)
        {
            var hex = Number(str.charCodeAt(n)).toString(16);
            arr1.push(hex);
        }
        return arr1.join('');
    }

    function hex_to_ascii(str1)
    {
        var hex  = str1.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    }

    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'sid': esid,
            'cfr': CFR,
            'nm': NAME
        }
    };

    fetch(URL, requestOptions)
        .then(function (response) {
            // console.log(response)
            return response.json();
        })
        .then(function (Gear) {
            // setGears(Gear)
            callback(Gear)

            return Gear
        });

}
