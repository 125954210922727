import {WialonAuth} from "../Resourses/WialonAPI/WialonAuthClass";
import {useState, useEffect} from "react";
import Preloader from "./PreloaderComponent";
import Vessels from "./VesselsComponent";
import Errors from "./ErrorsComponent";

function Home() {
    const [userData, setUserData] = useState({});
    const [newsid, setEid] = useState(null)

    function handleData(props, eid) {
        setUserData(props)
        setEid(eid)
    }

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const authHash = params.get("authHash");

    useEffect(() => {
        if (!newsid) {
            setEid(WialonAuth(authHash, handleData))
        }
    }, [])

    window.addEventListener('beforeunload', function (e) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
        delete e['returnValue']
    })

    return (
        JSON.stringify(userData) !== '{}' && (typeof userData !== "undefined") ?
            userData.error ?
                <Errors {...userData}/>
                :
                <Vessels {...userData}/>
            :
            <Preloader />
    )
}

export default Home;
