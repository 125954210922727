const __ISS_API_BASE_URI = "/api";
const __ISS_API_PAGE_URI = "/page/get/";

export const IssVesselSinglePage = async (props, ses) => {
    const page = props;
    const cfr = ses.cfr;
    const URL = __ISS_API_BASE_URI+__ISS_API_PAGE_URI+page;
    const esid = ses.sid;
    const INTERVAL_MS = 5000;
    // console.log("SES: "+JSON.stringify(ses))
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'sid': esid,
            'cfr': cfr,
            'num': page,
            'nm': ses.uid
        }
    };

        return fetch(URL,requestOptions)
            .then(function (response) {
                // console.log(response)
                return response.json();
            })
            .then(function (Page) {
                let p = Object.keys(Page).map((key) => Page[key]);
                // setJournals(d)
                // callback(p)
                // console.log("P: "+JSON.stringify(p))
                return Promise.resolve(p)
            })
            .then((Page) => {
                return Page;
            });
}
