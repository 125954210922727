import {Container, Navbar} from "react-bootstrap";

function NavBanner() {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const user = params.get('user');

    return (
        <Navbar expand="lg" variant="light" bg="white" style={{borderBottom: "1px solid grey"}}>
            <Container>
                <Navbar.Brand>Риболовни дневници и декларации</Navbar.Brand>
                {/*<Navbar.Toggle />*/}
                {/*<Navbar.Collapse className="justify-content-end">*/}
                    <Navbar.Brand>
                        Потребител: {user}
                    </Navbar.Brand>
                {/*</Navbar.Collapse>*/}
            </Container>
        </Navbar>
    )
}

export default NavBanner;
