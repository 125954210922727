import {css} from 'styled-components'

export const aaa = css`
  padding: 1.5rem;
  border-radius: 1rem;
  //border: 2px solid #c2c2c2;
  background-color: #e1e1e1
`;

export const bbb = css`
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: white;
`;

export const ports = css`
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: #b0cce1;
`;

export const ccc = css`
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: white;
`;
