import React, {useRef} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import ReactToPrint from "react-to-print";

function PrintUnloadComponent(props) {

    let unloadPort = props.unload_port

    let page = props.default_page
    let ulov = props.ulov

    let componentRef = useRef();

    return (
        <>
            <ReactToPrint
                trigger={() => <Button variant="outline-primary">Принтиране на декларация за разтоварване</Button>}
                content={() => componentRef}
            />
            <div style={{display: "none"}}>
                <Container
                    style={{maxWidth: "2480px", width: "100%", paddingTop: "100px", padding: "60px", margin: "0 auto"}}
                    ref={(el) => (componentRef = el)}>
                    <Row><Col style={{textAlign: "center"}}><h4><b>ДЕКЛАРАЦИЯ ЗА РАЗТОВАРВАНЕ</b></h4></Col></Row>
                    <br/>
                    {/*<br/>*/}
                    <div style={{border: "2px solid #8a8a8a"}}>
                        <Row style={{textAlign: "center"}}><Col><b>РИБОЛОВЕН КОРАБ</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Външна маркировка:</b></Col>
                        </Row>
                        <Row>
                            <Col>{page["ship_name"]}</Col>
                            <Col>{page["extmark"]}</Col>
                        </Row>
                        <br/>
                    </div>
                    <br/>
                    <div style={{border: "2px solid #8a8a8a"}}>
                        <Row style={{textAlign: "center"}}><Col><b>РАЗТОВАРВАНЕ</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Дата:</b></Col>
                            <Col><b>Пристанище:</b></Col>
                        </Row>
                        <Row>
                            <Col>{page["unload_date"]}</Col>
                            <Col>{unloadPort.length > 0 ? unloadPort[0]['n'] : 'NA'}</Col>
                        </Row>
                        <br/>
                    </div>
                    <br/>
                    <div style={{border: "2px solid #8a8a8a"}}>
                        <Row style={{textAlign: "center"}}><Col><b>ВИД РИБА ИЛИ ДРУГ МОРСКИ ОРГАНИЗЪМ</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Код:</b></Col>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Kоличество:</b></Col>
                            {/*<Col><b>Мярка:</b></Col>*/}
                            <Col><b>Представяне:</b></Col>
                        </Row>
                        {Object.entries(ulov["fishs"]).map((fish, k) => {
                            return (
                                <div key={k}>
                                    {fish.length > 1 ?
                                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                                        : null}
                                    <Row>
                                        <Col>{fish[1]["code"]}</Col>
                                        <Col>{fish[1]["name"]}</Col>
                                        <Col>{fish[1]["qty"]} {fish[1]["pres_measure"]}</Col>
                                        {/*<Col>{fish[1]["pres_measure"]}</Col>*/}
                                        <Col>{fish[1]["pres_name"]}</Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PrintUnloadComponent;
