const __ISS_API_BASE_URI = "/api/";
const __ISS_API_JOURNALS_URI = "journals/current/cfr/";

export const IssVesselJournals = (props, callback) => {
    const cfr = props.fun.vessel.cfr;
    const URL = __ISS_API_BASE_URI+__ISS_API_JOURNALS_URI+cfr;
    const esid = props.fun.vessel.sid;

    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'sid': esid,
            'cfr': cfr,
            'nm': props.fun.vessel.uid
        }
    };

    fetch(URL,requestOptions)
        .then(function (response) {
            // console.log(response)
            return response.json();
        })
        .then(function (Journal) {
            let d = Object.keys(Journal).map((key) => Journal[key]);
            callback(d)
            return(d)
        });
}
