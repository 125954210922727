// http://iss.iara.government.bg/scrtl/api/journals/archive/cfr/BGR002000102
// http://iss.iara.government.bg/scrtl/api/journals/archive/cfr/BGR002000102/year/2020

const __ISS_API_BASE_URI = "/api/";
const __ISS_API_JOURNALS_ARCHIVE_URI = "journals/archive/cfr/";
const __ISS_API_JOURNALS_ARCHIVE_YEAR_URI = "/year/";


export const IssVesselJournalsYearArchive = (props, yearVal, callback) => {
    const year = yearVal;
    const cfr = props.cfr;
    const URL = __ISS_API_BASE_URI+__ISS_API_JOURNALS_ARCHIVE_URI+cfr+__ISS_API_JOURNALS_ARCHIVE_YEAR_URI+year;
    const esid = props.sid;

    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'sid': esid,
            'cfr': cfr,
            'nm': props.uid,
            'year': year
        }
    };

    fetch(URL,requestOptions)
        .then(function (response) {
            // console.log(response)
            return response.json();
        })
        .then(function (Journal) {
            // console.log(Journal)
            let d = Object.keys(Journal).map((key) => Journal[key]);
            callback(d)
            return(d)
        });
}
