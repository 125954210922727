import {Col, Accordion, Row} from "react-bootstrap";
import CertsCheck from "./CertsCheckComponent";
import "./styles.css"
import Archive from "./ArchiveComponent";

function VesselRow(data) {

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey={0}>
                    <Accordion.Header className="row-header">
                        <Col>РК: {data["nm"]}</Col>
                        <Col>CFR: {data["cfr"]}</Col>
                        <Col className="uid">UID: {data["uid"]}</Col>
                    </Accordion.Header>

                    <Accordion.Body>
                        {/*visible when row expanded*/}
                        <Row>
                            <Col><CertsCheck {...data} /></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col><Archive {...data} /></Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default VesselRow;
