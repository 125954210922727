import VesselRow from "./VesselRowComponent";
import {Container, Form, Navbar} from "react-bootstrap";
import NavBanner from "./NavBannerComponent";
import {useState} from "react";

function Vessels(listOfArrays) {

    let intialData = normalizeDataToListOfArrays(listOfArrays)
    const [data, setData] = useState(intialData)

    function normalizeDataToListOfArrays(arr) {

        let normalizedData = []
        Object.entries(arr).map((item) => {
            normalizedData.push(item[1])
        })
        return normalizedData

    }

    //handle input in the search box
    function handleChange(chars, callback) {
        let __localArr = []
        intialData.filter(
            vessel => Object.values(vessel).toString().toLowerCase().includes(chars.toString().toLowerCase())).map(filteredData => (
            __localArr.push(filteredData)
        ))
        setData(__localArr)
    }

    function Rend() {
        //returns Accordion component - expandable rows for vessels
        //changes on input in the search box
        return (
            Object.entries(data).map((vessel, index) => (
                    <VesselRow key={index} {...vessel[1]}/>
                )
            )
        )
    }

    return (
        <div>
            {/*Header - NavBannerComponent*/}
            <NavBanner/>
            {/*Images*/}
            <Container style={{marginTop: "5vh"}}>
                <img src="./scortel.jpg" alt="Scortel" height="120px"/>
                <img src="./iara_pic.png" alt="NAFA Bulgaria" height="120px"/>
            </Container>
            {/*Search bar*/}
            <Form style={{width: "30vw", paddingTop: "5vh", margin: "0 auto"}}>
                <Form.Control placeholder={"Search"} onChange={(e) => handleChange(e.target.value)}/>
            </Form>
            {/*Main content - Vessel List - from function Rend() above*/}
            <Container fluid style={{paddingTop: "7vh", minHeight: "65vh"}}>
                <Rend/>
            </Container>
            {/*Footer*/}
            <Navbar stiky="bottom" variant="light" style={{marginTop: "5vh", backgroundColor: "white"}}>
                <Container>
                    <p className={"text-secondary"}>v1.1.0</p>
                    <p className={"text-secondary"}>2021-2022</p>
                    <p className={"text-secondary"}>by SCORTEL</p>
                </Container>
            </Navbar>
        </div>
    );
}

export default Vessels;
