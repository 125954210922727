import React, {useRef} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import ReactToPrint from "react-to-print";

function PrintCertComponent(props) {

    let page = props.default_page
    let gear = props.gear_name
    let fishName = props.fish_name
    let fishCode = props.fish_code
    let ulov = props.ulov

    // console.log("FISH IN PRINTCERTCOMP: "+fishName)
    let componentRef = useRef();

    return (
        <>
            <ReactToPrint
                trigger={() => <Button variant="outline-primary">Принтиране</Button>}
                content={() => componentRef}
            />
            <div style={{display: "none"}}>
                <Container
                    style={{maxWidth: "2480px", width: "100%", paddingTop: "100px", padding: "60px", margin: "0 auto"}}
                    ref={(el) => (componentRef = el)}>
                    <Row>
                        <Col style={{textAlign: "center"}}><h4><b>СЕРТИФИКАТ ЗА ПРОИЗХОД</b></h4></Col>
                    </Row>
                    {/*<br/>*/}
                    <br/>
                    <Row style={{textAlign: "right"}}><Col><b>Дата:</b> {ulov["data"]}</Col></Row>
                    <br/>
                    {/*<div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>*/}
                    <div style={{border: "2px solid #8a8a8a"}}>
                        <Row style={{textAlign: "center"}}><Col><b>РИБОЛОВЕН КОРАБ</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a"}}/>
                        <Row>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Външна маркировка:</b></Col>
                            {/*<Col><b>Използван риболовен уред:</b></Col>*/}
                        </Row>
                        <Row>
                            <Col>{page["ship_name"]}</Col>
                            <Col>{page["extmark"]}</Col>
                            {/*<Col>{gear["code"]} - {gear["name"]}</Col>*/}
                        </Row>
                        <br/>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                        <Row style={{textAlign: "center"}}><Col><b>РИБОЛОВЕН УРЕД</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                        <Row>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Код:</b></Col>
                        </Row>
                        <Row>
                            <Col>{gear["name"]}</Col>
                            <Col>{gear["code"]}</Col>
                        </Row>
                        <br/>
                    </div>
                    <br/>
                    <br/>
                    {/*<Row>*/}
                    {/*    <Col><b>Използван риболовен уред:</b></Col>*/}
                    {/*</Row>*/}
                    {/*<div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>*/}
                    {/*<Row>*/}
                    {/*    <Col>{gear["code"]} - {gear["name"]}</Col>*/}
                    {/*</Row>*/}
                    {/*<br/>*/}
                    <div style={{border: "2px solid #8a8a8a"}}>
                        {/*<Row>*/}
                        {/*    <Col><b>УЛОВ</b></Col>*/}
                        {/*    /!*<div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>*!/*/}

                        {/*    /!*<Row>*!/*/}
                        {/*    /!*<Col><b>Дата:</b> {ulov["data"]}</Col>*!/*/}
                        {/*</Row>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col>{ulov["data"]}</Col>*/}
                        {/*</Row>*/}
                        {/*<br/>*/}
                        {/*<div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>*/}
                        <Row style={{textAlign: "center"}}><Col><b>МЯСТО НА УЛОВА</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                        <Row>
                            <Col><b>Зона (FAO):</b></Col>
                            <Col><b>Квадрант:</b></Col>
                        </Row>
                        <Row>
                            <Col>{ulov["zona"]}</Col>
                            <Col>{ulov["pravoagalnik"] ? ulov["pravoagalnik"] : "NA"}</Col>
                        </Row>
                        <br/>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                        <Row style={{textAlign: "center"}}><Col><b>ВИД РИБА ИЛИ ДРУГ МОРСКИ ОРГАНИЗЪМ</b></Col></Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                        <Row>
                            <Col><b>Наименование:</b></Col>
                            <Col><b>Код:</b></Col>
                        </Row>
                        <Row>
                            <Col>{fishName}</Col>
                            <Col>{fishCode}</Col>
                        </Row>
                        <br/>
                    </div>
                    <br/>
                </Container>
            </div>
        </>
    )
}

export default PrintCertComponent;
