import {Accordion, Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import Journals from "./JournalsComponent";
import {useState} from "react";

function GearComponent(props) {
    let g = []

    const result = Object.keys(props).map((key) => props[key]);
    result.map((item, i) => {
        let itm = Object.keys(item).map((key) => item[key]);
        g.push(Object.keys(itm).map((key) => itm[key]))
    })

    const RenderGears = (data) => {
        return (
            Object.entries(data).map((subItem, n) => (
                <Card key={n}>
                    <Card.Body>
                        <Card.Title>{subItem[1].code}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{subItem[1].name}</Card.Subtitle>
                        <Row>
                            <Col><b>№</b></Col>
                            <Col><b>Код</b></Col>
                            <Col><b>Око</b></Col>
                            <Col><b>Количество</b></Col>
                            <Col><b>Марки</b></Col>
                        </Row>
                        <div className="rounded" style={{borderTop: "2px solid #8a8a8a", borderRadius: "2px"}}/>
                        <Row>
                            <Col>{subItem[1].udo_id}</Col>
                            <Col>{subItem[1].code}</Col>
                            <Col>{subItem[1].oko}</Col>
                            <Col>{subItem[1].qty}</Col>
                            <Col>{subItem[1].marki}</Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))
        )
    }

    function ControlledTabs(item) {
        const [key, setKey] = useState('journals');
        const [jur, setJur] = useState(false)
        return (
            <Tabs
                key={"tabs" + item[0].toString()}
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                {/*Tab with Gears info*/}
                <Tab eventKey="gears" title="Уреди">
                    {RenderGears(item[1])}
                </Tab>
                {/*Tab with Journals info*/}
                <Tab eventKey="journals" title="Дневници" mountOnEnter={!jur}>
                    <Journals item={item[0]} fun={props}/>
                </Tab>
            </Tabs>
        );
    }

    const getNode = (data) => {
        return (
            <>
                {Object.entries(data).map((item, o) => (
                    item[0].length > 6 ?
                        <Accordion key={o}>
                            <Accordion.Item eventKey={0}>
                                <Accordion.Header>
                                    Удостоверение № {item[0]} {item[0]
                                    .split('K').length > 1 ?
                                    " към Специално разрешително за улов на калкан "
                                    : " към Общо разрешително за улов "
                                }
                                    с № {item[0].split('-')[0]}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ControlledTabs {...item} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        :
                        null
                ))}
            </>
        );
    };

    return (
        <div>
            {getNode(props)}
        </div>
    )
}


export default GearComponent
